import React from 'react';

import Modal from 'src/components/UI/Modal/ModalPortal';

import classes from 'src/components/UI/AlertModal/AlertModal.module.scss';

import { alertModalDataType } from 'src/models/appTypes';

const AlertModal: React.FC<{ data: alertModalDataType; onClose: () => void; type?: string, showTextButton?: string }> = ({ data, onClose, type = 'Success', showTextButton = "" }) => {

    return (
        <Modal onClose={onClose}>
            <section className={classes.alert}>
                <div className={classes.close} onClick={(e) => onClose()}><i className="bi bi-x"></i></div>
                <div className={classes.content}>
                    <div className={classes.icon}>{type === 'Success' ? <i className="bi bi-check-square text-success"></i> : <i className="bi bi-exclamation-triangle text-warning"></i> }</div>
                    <div className={classes.text}>
                        {data.message}
                        <ul>{data.errorDetails && data.errorDetails.map((detail: string, i: number) => <li key={i}>{detail}</li>)}</ul>
                    </div>
                </div>
                <div className={classes.footer}>
                    { showTextButton.length > 0 && (
                        <div className={classes['close-button']}>
                            <button type="button" onClick={(e) => onClose()}>
                                {showTextButton}
                            </button>
                        </div>)
                    }
                </div>

            </section>
        </Modal>
    );
};

export default AlertModal;
