import React, { useState, useEffect, useCallback, useContext } from 'react';

import { CsvDescriptionsContextObj } from 'src/models/appTypes';
import { InputProps } from 'src/models/appTypes';

const CSV_URL = process.env.REACT_APP_DESCRIPTIONS_CSV_URL || '';

export const CsvDescriptionsContext = React.createContext<CsvDescriptionsContextObj>({
    isLoading: false,
    csvDescriptionsData: '',
} as CsvDescriptionsContextObj);

const CsvDescriptionsContextProvider: React.FC<InputProps> = (props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [csvData, setCsvData] = useState<any>([]);

    const parseCSV = (csvText: any) => {
        const rows = csvText.split(/\r?\n/); // Split rows, handling '\r'
        const headers = rows[0].split(',');  // Assume first row is headers
        const data = [];                     // Array to hold parsed data

        for (let i = 1; i < rows.length; i++) {
            let rowData = rows[i].trim(); // Trim whitespace from start and end of row
            let rowArray = [];
            let inQuotes = false;
            let field = '';

            for (let j = 0; j < rowData.length; j++) {
                const char = rowData[j];
                if (char === '"' && rowData[j - 1] !== '\\') {
                    // Found a quote not preceded by a backslash, toggle inQuotes
                    inQuotes = !inQuotes;
                    continue;
                }
                if (char === ',' && !inQuotes) {
                    // Found a comma outside of quotes, push the current field and reset
                    rowArray.push(field);
                    field = '';
                    continue;
                }
                // Add the character to the current field
                field += char;
            }
            // Push the last field if it exists
            if (field) {
                rowArray.push(field);
            }

            const rowObject = {};
            for (let j = 0; j < headers.length; j++) {
                rowObject[headers[j]] = rowArray[j];
            }
            data.push(rowObject);
        }

        return data;
    };

    const fetchCSVData = useCallback(() => {
        setIsLoading(true);
        fetch(CSV_URL)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error: ${response.status}`);
                }
                return response.text();
            })
            .then((rawCsvData) => {
                const parsedCsvData = parseCSV(rawCsvData);
                setCsvData(parsedCsvData);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching CSV data:', error);
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchCSVData();
    }, [fetchCSVData]);

    const contextValue: CsvDescriptionsContextObj = {
        isLoading: isLoading,
        csvDescriptionsData: csvData,
    };

    return <CsvDescriptionsContext.Provider value={contextValue}>{props.children}</CsvDescriptionsContext.Provider>;
};

export default CsvDescriptionsContextProvider;

export const useCsvDescriptionsContext = () => useContext(CsvDescriptionsContext);
