import React, { useCallback, useEffect } from 'react';
import i18next from 'i18next';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import AppRoutes from 'src/AppRoutes';
import ShowAlert from 'src/components/core/ShowAlert/ShowAlert';
import Layout from 'src/components/UI/Layout/Layout';

import PlayFabContextProvider from 'src/store/playfab-context';
import AuthContextProvider from 'src/store/auth-context';
import CsvDescriptionsContextProvider from 'src/store/csv-descriptions-context';
import useLocalStorage from 'src/hooks/useLocalStorage';

import { localStorageSettingsType } from 'src/models/appTypes';


import 'src/i18n';

const LOCAL_STORAGE_SETTINGS_KEY = process.env.REACT_APP_LOCAL_STORAGE_SETTINGS_KEY || '';

function App() {
    const { i18n } = useTranslation();
    const [ localStorageSettings, setLocalStorageSettings ] = useLocalStorage(LOCAL_STORAGE_SETTINGS_KEY);

    useEffect(() => {
        console.log(
            '%cWelcome to Metaworlds!',
            'font-size:  24px; font-weight: 600;',
            '\n\nDo you need more information about our application? Please visit https://coolections.games/',
            '\n\n⭐Thank you for use Metaworlds! ⭐\n\n',
        );

    }, []);

    const changeLanguageHandler = (language: string) => {
        setLocalStorageSettings((oldConfig: localStorageSettingsType) => ({
            ...oldConfig,
            language: language
        }));

        i18n.changeLanguage(language);
    };

    const setLanguage = useCallback((language: string) => {
        i18n.changeLanguage(language);
    }, [i18n]);

    useEffect(() => {
        if (localStorageSettings) {
            setLanguage(localStorageSettings.language);
        }

    }, [localStorageSettings, setLanguage]);
 
    return (
        <I18nextProvider i18n={i18next}>
            <BrowserRouter>
                <AuthContextProvider>
                    <CsvDescriptionsContextProvider>
                        <PlayFabContextProvider>
                            <Layout onChangeLanguage={changeLanguageHandler} >
                                <AppRoutes />
                                <ShowAlert />
                            </Layout>
                        </PlayFabContextProvider>
                    </CsvDescriptionsContextProvider>
                </AuthContextProvider>
            </BrowserRouter>
        </I18nextProvider>
    );
}

export default App;
