export enum WonderWars {
    LEADERS_CATALOG = "Leaders Def",
    WONDERS_CATALOG = "Wonders Def",
    WONDERS_CATALOG_CARDS = "Cartas Wonders",
    LEADERS_CATALOG_CARDS = "Cartas Leaders",
    CHEST_CATALOG = "Chest",
    SKILLS_CATALOG = "Skills",
    STICKERS_CATALOG = "Stickers",
    STORE_CATALOG = "Store 0.1",
    STORE_CREATOR_CATALOG = "StoreCreator",
    STORE_PAY_TO_WIN_CATALOG = "StorePayToWin",
    ORUMIT_CATALOG = "Orumit",
    TITLES_CATALOG = "Titles",
    AVATAR_CATALOG = "Avatar",
    PEANAS_CATALOG = "PeanasLobby",
    BATTLE_MAPS_CATALOG = "BattleMaps",
    SKIN_CATALOG = "Skins",
    FRAME_CATALOG = "Frame",
    PROFILE_CARD_CATALOG = "ProfileCard",
    SPECIAL_CATALOG = "Special",
};

export enum Categories {
    TROOPS = "Troops",
    HEROES = "Heroes",
    EMOTES = "Emotes",
    PETS = "Pets",
    ORUMITS = "Orumits",
    PLAYER_PROFILE = "Player Profile",
}

export enum FilterCategories {
    TROOPS = "troops",
    HEROES = "heroes",
    EMOTES = "emotes",
    PETS = "pets",
    ORUMITS = "orumits",
    PLAYER_PROFILE = "playerProfile",
}
