import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'src/store/auth-context';
import { PlayFabContext } from 'src/store/playfab-context';

import classes from 'src/components/app/NavBar/NavBar.module.scss';

import logoImg from 'src/assets/images/chili-cheese-games-logo-light.png';
// import esLangImg from 'src/assets/images/es.svg';
import enLangImg from 'src/assets/images/en.svg';

interface languageMenu {
    en: boolean,
    es: boolean
};

const NavBar: React.FC<{ data: any, onChangeLanguage: (language: string) => void}> = ({data, onChangeLanguage}) => {
    const [menuOpened, setMenuOpened] = useState<boolean>(false);
    const { isLoggedIn, logoutHandler } = useContext(AuthContext);
    const { language, setLanguageHandler } = useContext(PlayFabContext);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const initialLanguageMenuClasses: languageMenu = {
        en: false,
        es: false
    };

    const [languageMenuActive, setLanguageMenuActive] = useState<languageMenu>(initialLanguageMenuClasses);

    const setBodyBlocked = (menuOpened: boolean) => {
        document.body.style.overflow = menuOpened ? 'hidden' : 'unset';
    };

    useEffect(() => {
        setBodyBlocked(menuOpened);
    }, [menuOpened]);

    useEffect(() => {
        const languageSelected = language;
        setLanguageMenuActive((prevState) => ({ ...prevState, [languageSelected]: true }));

    }, [language]);

    const toggleMenu = () => {
        setMenuOpened((prevState) => !prevState);
    };

    const closeOthers = (el: any) => {
        const linksClass = classes.links;
        const showClass = classes.show;
        const linksElements = document.querySelector(`.${linksClass}`)?.children;

        for (let i = 0; i < linksElements!.length; i++) {
            const link = linksElements![i];
            if (el !== link && link.classList.contains(showClass)) {
                link.classList.remove(showClass);
            }
        }
    }

    const togleSubmenu = (event: any) => {
        const showClass = classes.show;
        closeOthers(event.currentTarget);
        event.currentTarget.classList.toggle(showClass);
    };

    const openRoute = (route: string) => {
        toggleMenu();
        navigate(route);
        // navigate(0);
    };

    const setLanguage = (event: React.FormEvent, language: string) => {
        event.preventDefault();

        setLanguageMenuActive({ ...initialLanguageMenuClasses });
        setLanguageMenuActive((prevState) => ({ ...prevState, [language]: true }));
        setLanguageHandler(language);
        onChangeLanguage(language);
    }

    const logOut = () => {
        console.log('logOut');
        logoutHandler();
    };
    
    return (
        <nav>
            <div className={classes.navbar}>
                <div className={classes.left}>
                    <div className={classes.logo}>
                        <div className={classes.overlap}>
                            <img src={logoImg} alt="Coolections logo" onClick={() => navigate('/home')} />
                        </div>
                    </div>
                </div>
                <div className={classes.middle}>
                    <div className={menuOpened ? `${classes['nav-links']} ${classes.opened}` : classes['nav-links']}>
                        <ul className={classes.links}>
                            <li>
                                <Link to="https://coolections.games/" title={t('navBar.home')} >
                                    <span className={classes['menu-image-title']}>{t('navBar.home')}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="https://coolections.games/#games" title={t('navBar.games')} >
                                    <span className={classes['menu-image-title']}>{t('navBar.games')}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="https://coolections.games/#about" title={t('navBar.aboutUs')} >
                                    <span className={classes['menu-image-title']}>{t('navBar.aboutUs')}</span>
                                </Link>
                            </li>
                            { isLoggedIn && (
                                <li onClick={(event) => togleSubmenu(event)}>
                                    <Link to={undefined} title="Profile" >
                                        <span>{t('navBar.profile')}</span>
                                        <i className={`bi bi-chevron-down ${classes.arrow}`}></i>
                                    </Link>
                                    <ul className={`${classes['js-sub-menu']} ${classes['sub-menu']}`}>
                                        <li onClick={() => openRoute('/privacy')}>
                                            <i className="bi bi-hexagon"></i>{t('navBar.privacy')}
                                        </li>
                                        <li onClick={(e) => logOut()}>
                                            <i className="bi bi-hexagon"></i>{t('navBar.logout')}
                                        </li>
                                    </ul>
                                </li>
                            )}
                            <li onClick={(event) => togleSubmenu(event)}>
                                <Link to="" title="Language">
                                    <span>{t('navBar.language')}</span>
                                    <i className={`bi bi-chevron-down ${classes.arrow}`}></i>
                                </Link>
                                <ul className={`${classes['js-sub-menu']} ${classes['sub-menu']}`}>
                                    <li className={`${classes.language} ${languageMenuActive['en'] ? classes['language-selected'] : ''}`}>
                                        <Link to="" title={t('navBar.language_english')} onClick={(e) => setLanguage(e, 'en')}>
                                            <img className={classes['language-img']} src={enLangImg} alt="" />
                                            <span className={classes.language} lang="en">
                                                {t('navBar.language_english')}
                                            </span>
                                        </Link>
                                    </li>
                                   {/*  <li className={`${classes.language} ${languageMenuActive['es'] ? classes['language-selected'] : ''}`}>
                                        <Link to="" title={t('navBar.language_spanish')} onClick={(e) => setLanguage(e, 'es')}>
                                            <img className={classes['language-img']} src={esLangImg} alt="" />
                                            <span className={classes.language} lang="es">
                                                 {t('navBar.language_spanish')}
                                            </span>
                                        </Link>
                                    </li> */}
                                </ul>
                            </li>
                           
                        </ul>

                    </div>
                </div>
                <div className={classes.right}>
                    <div className={classes['list-menu']} onClick={() => toggleMenu()}>
                        <i className="bi bi-list"></i> <span>{t('navBar.menu')}</span>
                    </div>
                    
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
