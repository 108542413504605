import React from 'react';

import NavBar from 'src/components/app/NavBar/NavBar';

interface LayoutProps {
    children: React.ReactNode;
    onChangeLanguage: (language: string) => void;
}

const Layout: React.FC<LayoutProps> = (props) => {

    const changeLanguageHandler = (language: string) => {
        props.onChangeLanguage(language);
    };

    return (
        <>
            <NavBar data={null} onChangeLanguage={changeLanguageHandler} />
            <div>{props.children}</div>
        </>
    );
};

export default Layout;
