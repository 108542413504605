import { lazy, Suspense, useContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import Loading from 'src/components/UI/Loading/Loading';
import Protected from 'src/components/core/Protected/Protected';
import NotFound from 'src/pages/NotFound/NotFound';

import { AuthContext } from 'src/store/auth-context';

const Login = lazy(() => import('src/pages/Login/Login'));
const SignUp = lazy(() => import('src/pages/SignUp/SignUp'));
const RecoverPassword = lazy(() => import('src/pages/RecoverPassword/RecoverPassword'));
const ChangePassword = lazy(() => import('src/pages/ChangePassword/ChangePassword'));
const Home = lazy(() => import('src/pages/Home/Home'));
const Privacy = lazy(() => import('src/pages/Privacy/Privacy'));

const AppRoutes = () => {
    const { isLoggedIn } = useContext(AuthContext);

    return (
        <Routes>
            <Route path="/" element={<Navigate replace to="/login" />} />
            <Route
                path="/login"
                element={
                    <Suspense fallback={<Loading />}>
                        <Login />
                    </Suspense>
                }
            />
            <Route
                path="/sign-up"
                element={
                    <Suspense fallback={<Loading />}>
                        <SignUp />
                    </Suspense>
                }
            />
            <Route
                path="/recover-password"
                element={
                    <Suspense fallback={<Loading />}>
                        <RecoverPassword />
                    </Suspense>
                }
            />
            <Route
                path="/change-password"
                element={
                    <Suspense fallback={<Loading />}>
                        <ChangePassword />
                    </Suspense>
                }
            />
            <Route path="/home"
                element={
                    <Suspense fallback={<Loading />}>
                        <Protected isLoggedIn={isLoggedIn}>
                            <Home />
                        </Protected>
                    </Suspense>
                }
            />
              <Route path="/privacy"
                element={
                    <Suspense fallback={<Loading />}>
                        <Protected isLoggedIn={isLoggedIn}>
                            <Privacy />
                        </Protected>
                    </Suspense>
                }
            />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default AppRoutes;
