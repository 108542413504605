import React, { useState, useEffect, useCallback, useContext } from 'react';

import useLocalStorage from 'src/hooks/useLocalStorage';
import { isEmptyObject } from 'src/utils';
import { PlayFabContextObj } from 'src/models/playFabModels';
import { InputProps } from 'src/models/appTypes';

const LOCAL_STORAGE_KEY = process.env.REACT_APP_LOCAL_STORAGE_DATA_KEY || '';
const LOCAL_STORAGE_SETTINGS_KEY = process.env.REACT_APP_LOCAL_STORAGE_SETTINGS_KEY || '';

export const PlayFabContext = React.createContext<PlayFabContextObj>({
    language: '',
    playerData: {},
    catalog: {},
    playerStatistics: {},
    infoResultPayload: {},
    setLanguageHandler: (language: string ) => {},
    setInfoResultPayloadHandler: (data: any ) => {},
    setPlayerDataHandler: (userData: any ) => {},
    setPlayerStatisticsHandler: (data: any) => {},
    setCatalogHandler: (catalogData: any) => {},
} as PlayFabContextObj);

const PlayFabContextProvider: React.FC<InputProps> = ( props ) => {
    const [ language, setLanguage ] = useState<string>('');
    const [ playerData, setPlayerData ] = useState<any>({});
    const [ playerStatistics, setPlayerStatistics ] = useState<any>({});
    const [ infoResultPayload, setInfoResultPayload ] = useState<any>({});
    const [ catalog, setCatalog ] = useState<any>({});
    const [ localStorageConfig ] = useLocalStorage(LOCAL_STORAGE_KEY);
    const [ localStorageSettings ] = useLocalStorage(LOCAL_STORAGE_SETTINGS_KEY);

    const retrieveStoredData = useCallback(() => {
        if (localStorageConfig && !isEmptyObject(localStorageConfig.loginData) && !isEmptyObject(localStorageConfig.infoResultPayload) && !isEmptyObject(localStorageConfig.playerStatistics) ) {
            setInfoResultPayloadHandler(localStorageConfig.infoResultPayload);
            setPlayerStatisticsHandler(localStorageConfig.playerStatistics);
            setPlayerDataHandler(localStorageConfig.playerData);
        }

        if (localStorageSettings && localStorageSettings.language !== '') {
            setLanguage(localStorageSettings.language);
        }

    }, [localStorageConfig, localStorageSettings]);

    useEffect(() => {
        retrieveStoredData();
    }, [retrieveStoredData]);

    const setLanguageHandler = (language: string) => {
        setLanguage(language);
    };

    const setPlayerDataHandler = (userData: any) => {
        setPlayerData(userData);
    };

    const setInfoResultPayloadHandler = (data: any) => {
        setInfoResultPayload(data);
    };

    const setPlayerStatisticsHandler = (data: any) => {
        setPlayerStatistics(data);
    };

    const setCatalogHandler = (catalogData: any) => {
        setCatalog(catalogData);
    };

    const contextValue: PlayFabContextObj = {
        language: language,
        playerData: playerData,
        playerStatistics: playerStatistics,
        infoResultPayload: infoResultPayload,
        catalog: catalog,
        setLanguageHandler: setLanguageHandler,
        setInfoResultPayloadHandler: setInfoResultPayloadHandler,
        setPlayerDataHandler: setPlayerDataHandler,
        setPlayerStatisticsHandler: setPlayerStatisticsHandler,
        setCatalogHandler: setCatalogHandler,
    };

    return <PlayFabContext.Provider value={contextValue}>
        {props.children}
    </PlayFabContext.Provider>
};

export default PlayFabContextProvider;

export const usePlayFabContext = () => useContext(PlayFabContext);
