import { imageInputDataType, imageOutputDataType, alertModalDataType } from 'src/models/appTypes';
import { GameCard, PlayerItemData, CatalogData, SkillDataType } from 'src/models/playFabModels';
import { WonderWars, Categories, FilterCategories } from 'src/enums';

export const isEmptyObject = (obj: any): boolean => {
    if (!obj) return true;

    return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
};

export const tryParseJSONObject = (jsonString: string) => {
    try {
        const o = JSON.parse(jsonString);
        // JSON.parse(null) returns null, and typeof null === "object", so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === 'object') {
            return o;
        }
    } catch (e) {}

    return false;
};

export const parseData = (data: any) => {
    let parsedData: any = {};
    for (const prop in data) {
        parsedData[prop] = JSON.parse(data[prop]!);
    }

    return parsedData;
};

export const convertArrayToObject = (array: any[], key: string) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
        obj[item.id] = {};
        Object.keys(item).forEach(function (k) {
            if (k !== key) obj[item.id] = Object.assign(obj[item.id], { [k]: item[k] });
        });
        return obj;
    }, initialValue);
};

export const isElementExists = (array: any[], value: any, key?: string): boolean => {
    let found = false;
    if (key) {
        found = array.some(el => el[key] === value[key]);
    } else {
        found = array.some(el => el === value);
    }
    return found;
};

export const loadImageData = (imageData: imageInputDataType, path?: string) => {
    return new Promise((resolve, reject) => {
        const cData: imageOutputDataType = {
            id: imageData.imageId,
            alt: imageData.alt,
            imageName: imageData.imageName,
            src: require(`./assets/${imageData.path}${imageData.imageName}`).default,
        };

        const image = new Image();
        image.src = cData.src;

        image.onload = () => resolve(cData);
        image.onerror = (error) => reject(error);
    });
};

export const getErrorDetails = (error: any) => {
    let errorDetails: string[] = [];
    let parsedError: alertModalDataType = {
        message: error.errorMessage,
        errorDetails: [],
    };

    if (typeof error === 'string') {
        parsedError.message = error;
    } else {
        for (const prop in error.errorDetails) {
            error.errorDetails[prop].forEach((err: string) => {
                errorDetails.push(`${prop}: ${err}`);
            });
        }
    
        parsedError.errorDetails = errorDetails;
    }
  
    return parsedError;
};

export const createIdKey = (length: number = 5) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
};

export const formatNumber = (num: number) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const getPlayerValue = (obj: any, statisticName: string) => {

    for (let prop in obj) {
        if (obj.hasOwnProperty(prop) && obj[prop].StatisticName === statisticName) {
            return obj[prop].Value;
        }
    }
    return null;
};

export const findPlayerLevel = (value: number, levelList: any[]) => {
    let start = 0;
    let end = levelList.length - 1;

    while (start <= end) {
        let mid = Math.floor((start + end) / 2);
        if (levelList[mid].value === value) {
            return levelList[mid].level;
        } else if (levelList[mid].value < value) {
            // If mid is the last index return its level, otherwise check if value is less than the next index's value
            if (mid === levelList.length - 1) {
                return levelList[mid].level;
            } else if (value < levelList[mid + 1].value) {
                return levelList[mid + 1].level;
            }
            // Search in the right half
            start = mid + 1;
        } else {
            // Search in the left half
            if (mid === 0) {
                return 0; // If mid is the first index and value is less than it, return 0
            }
            end = mid - 1;
        }
    }
    return -1; // Value not found
};

export const getPlayerData = (userInventoryData: any) => {
    const playerData: PlayerItemData[] = [];

    userInventoryData.forEach((item: any) => {
        let newItem: GameCard;
        if (item.CatalogVersion === WonderWars.WONDERS_CATALOG || item.CatalogVersion === WonderWars.LEADERS_CATALOG) {
            let match = userInventoryData.find((i: any) => (i.CatalogVersion === WonderWars.WONDERS_CATALOG_CARDS || i.CatalogVersion === WonderWars.LEADERS_CATALOG_CARDS) && i.ItemId === item.ItemId);
            newItem = {
                Category: '',
                FilterCategory: '',
                ItemId: item.ItemId,
                DisplayName: item.DisplayName,
                UnitLevel: item.CustomData.UnitLevel,
                RemainingUses: match ? match.RemainingUses : 0,
                PurchaseDate: item.PurchaseDate
            };
            if (item.CatalogVersion === WonderWars.WONDERS_CATALOG) {
                newItem.Category = Categories.TROOPS;
                newItem.FilterCategory = FilterCategories.TROOPS;
                playerData.push(newItem);
            } else {
                newItem.Category = Categories.HEROES;
                newItem.FilterCategory = FilterCategories.HEROES;
                playerData.push(newItem);
            }

        } else if (item.CatalogVersion === WonderWars.STICKERS_CATALOG) {
            playerData.push({ ItemId: item.ItemId, Category: Categories.EMOTES, FilterCategory: FilterCategories.EMOTES, DisplayName: item.DisplayName });
        } else if (item.CatalogVersion === WonderWars.ORUMIT_CATALOG) {
            playerData.push({ ItemId: item.ItemId, Category: Categories.PETS, FilterCategory: FilterCategories.PETS, DisplayName: item.DisplayName });
        } else if (item.CatalogVersion === WonderWars.FRAME_CATALOG || item.CatalogVersion === WonderWars.AVATAR_CATALOG || item.CatalogVersion === WonderWars.PROFILE_CARD_CATALOG) {
            playerData.push({ ItemId: item.ItemId, Category: Categories.PLAYER_PROFILE, FilterCategory: FilterCategories.PLAYER_PROFILE, DisplayName: item.DisplayName, CatalogVersion: item.CatalogVersion });
        }
    });

    return playerData;
};

const getDescription = (skillData: SkillDataType, csvDescriptionsData: any) => {

    const descriptionObj = csvDescriptionsData.find((descObj: any) => descObj['Unique Name'] === skillData.Description);
        
    if (!descriptionObj) {
        return null;
    }

    let translatedText = descriptionObj.Translation;
    translatedText = translatedText.replace(/<color=(#[0-9a-fA-F]{6})>(.*?)<\/color>/g, '<span style="color: $1">$2</span>');
    translatedText = translatedText.replace(/\{0\}/g, skillData.value);
    translatedText = translatedText.replace(/\{2\}/g, skillData.value2);
    
    return translatedText;
};

const findCatalogData = (player: PlayerItemData, catalog: any): CatalogData | undefined => {
    switch (player.Category) {
        case Categories.HEROES:
            return catalog.Hero.find((data: CatalogData) => data.ItemId === player.ItemId);
        case Categories.TROOPS:
            return catalog.Troop.find((data: CatalogData) => data.ItemId === player.ItemId);
        case Categories.EMOTES:
            return catalog.Emote.find((data: CatalogData) => data.ItemId === player.ItemId);
        case Categories.PETS:
            return catalog.Pets.find((data: CatalogData) => data.ItemId === player.ItemId);
        case Categories.PLAYER_PROFILE:
            switch (player.CatalogVersion) {
                case 'Frame':
                    return catalog.Frame.find((data: CatalogData) => data.ItemId === player.ItemId);
                case 'Avatar':
                    return catalog.Avatars.find((data: CatalogData) => data.ItemId === player.ItemId);
                case 'ProfileCard':
                    return catalog.ProfileCard.find((data: CatalogData) => data.ItemId === player.ItemId);
                case 'Title':
                    return catalog.Title.find((data: CatalogData) => data.ItemId === player.ItemId);
                case 'Pedestal':
                    return catalog.Pedestal.find((data: CatalogData) => data.ItemId === player.ItemId);
            }
    }
};


export const getPlayerDataWithCatalog = (playerData: any, catalog: any, csvDescriptionsData: any) => {
    return playerData.map((player: PlayerItemData) => {
        const update: CatalogData = findCatalogData(player, catalog)!;
        const { CustomData, ImageUri, SkillData, ItemId } = update;

        switch (player.Category) {
            case Categories.HEROES:
            case Categories.TROOPS:
                return {
                    ...player,
                    ItemId,
                    Custom: CustomData,
                    ImageUri,
                    SkillName: SkillData!.DisplayName,
                    Description: getDescription(SkillData!, csvDescriptionsData)
                };
            case Categories.EMOTES:
            case Categories.PETS:
            case Categories.PLAYER_PROFILE:
                return {
                    ...player,
                    ItemId,
                    ImageUri: ImageUri || ''
                };
        }
        return player;

    });
};

export const getLastDotStringValue = (str: string) => {
    if (str.includes('.')) {
        const [rest, value] = str.split(/\.(?=[^.]+$)/);
        return value;
    }

    return str;
}

export const wait = (milliseconds: number) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(null);
        }, milliseconds);
    });
};

