import { useTranslation } from 'react-i18next';

const NotFound = () => {
    const { t } = useTranslation();

    return (
        <div className="page-wrap d-flex flex-row align-items-center mt-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <span className="display-1 d-block">404</span>
                        <div className="mb-4 lead">{t('common.notFoundMsg')}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
