import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AlertModal from 'src/components/UI/AlertModal/AlertModal';
import { AlertEventChannel } from 'src/components/eventChannels/AlertEventChannel';

function ShowAlert() {
    const { t } = useTranslation();
    const [ alertIsShown, setAlertIsShown ] = useState<boolean>(false);
    const [ alertData, setAlertData ] = useState<any>();
 
    useEffect(() => {
        // subscribe to events when mounting
        const unsubscribeOnAlert = AlertEventChannel.on(
          'onSendAlert',
          (payload) => {

            setAlertIsShown(true);
            const data = {
                message: {
                    message: payload.message
                },
                type: payload.type
            };

            setAlertData(data);
          }
        )
        // unsubscribe events when unmounting
        return () => {
            unsubscribeOnAlert()
        }
    }, []);

    const hideAlertHandler = () => {
        setAlertIsShown(false);
    };

    return (
        <>
            { alertIsShown && <AlertModal data={alertData.message} type={alertData?.type} onClose={hideAlertHandler} showTextButton={t('common.acceptBtn')} /> }
        </>
    )
}

export default ShowAlert;
