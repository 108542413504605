import { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { PlayFab, PlayFabClient, PlayFabCloudScript } from 'playfab-sdk';

import useHttp from 'src/hooks/useHttp';
import { AuthContext } from 'src/store/auth-context';
import { CsvDescriptionsContext } from 'src/store/csv-descriptions-context';
import { PlayFabContext } from 'src/store/playfab-context';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { isEmptyObject, getPlayerValue, findPlayerLevel, getPlayerData, getPlayerDataWithCatalog } from 'src/utils';

import { loginWithEmailAddressRequest, registerPlayFabUserRequest } from 'src/models/playFabModels';
import { localStorageDataType, localStorageSettingsType, registerUserRequest } from 'src/models/appTypes';


const localStorageData: localStorageDataType = {
    token: '',
    expirationTime: '',
    loginData: {},
    infoResultPayload: {},
    playerData: {},
    playerStatistics: {},
    catalog: {},

};

const localStorageSettingsData: localStorageSettingsType = {
    language: 'en',
};

const LOCAL_STORAGE_KEY = process.env.REACT_APP_LOCAL_STORAGE_DATA_KEY || '';
const LOCAL_STORAGE_SETTINGS_KEY = process.env.REACT_APP_LOCAL_STORAGE_SETTINGS_KEY || '';
const RECOVER_PASSWORD_URL = process.env.REACT_APP_PLAYFAB_RECOVER_PASSWORD_URL || '';
const CHANGE_PASSWORD_URL = process.env.REACT_APP_PLAYFAB_CHANGE_PASSWORD_URL || '';
const GET_PLAYFAB_CATALOG_URL = process.env.REACT_APP_PLAYFAB_CATALOG_URL || '';

const usePlayFab = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<any>(null);
    const [isLogged, setIsLogged] = useState<boolean>(false);
    const { setLoginDataHandler } = useContext(AuthContext);
    const { csvDescriptionsData } = useContext(CsvDescriptionsContext);
    const { setPlayerDataHandler, setInfoResultPayloadHandler, setPlayerStatisticsHandler, setCatalogHandler } = useContext(PlayFabContext);
    const { error: httpError, sendRequest: sendForm } = useHttp();
    const [ localStorageConfig, setLocalStorageConfig ] = useLocalStorage(LOCAL_STORAGE_KEY);
    const [ localStorageSettings, setLocalStorageSettings ] = useLocalStorage(LOCAL_STORAGE_SETTINGS_KEY);

    const storageInizialized = useRef<boolean>(false);

    // Settings config
    PlayFab.settings.titleId = process.env.REACT_APP_PLAYFAB_TITLE_ID!;
    PlayFab.settings.developerSecretKey = process.env.REACT_APP_PLAYFAB_DEVELOPER_SECRET_KEY;

    const checkUserSettings = useCallback((): boolean => {
        if (localStorageSettings && (localStorageSettings.language !== null)) {
            return false;
        } 
        return true;
    }, [localStorageSettings]);

    useEffect(() => {
        if (checkUserSettings()) {
            setLocalStorageSettings(localStorageSettingsData);
        }
    }, [checkUserSettings, setLocalStorageSettings]);

    const isLoggedCheck = useCallback((): boolean => {
        if (!localStorageConfig || isEmptyObject(localStorageConfig.loginData) || isEmptyObject(localStorageConfig.infoResultPayload) || isEmptyObject(localStorageConfig.playerStatistics) || isEmptyObject(localStorageConfig.catalog)) {
            return false;
        }

        const currentDate = new Date();
        const expirationDate = new Date(localStorageConfig.loginData.EntityToken.TokenExpiration);
        const isValidToken = currentDate < expirationDate ? true : false;
        const hasData = !isEmptyObject(localStorageConfig.loginData) && !isEmptyObject(localStorageConfig.infoResultPayload) && !isEmptyObject(localStorageConfig.playerStatistics) && !isEmptyObject(localStorageConfig.catalog);
        
        return hasData && isValidToken;
    }, [localStorageConfig]);

    const setAppData = useCallback((): void => {
        setLoginDataHandler(localStorageConfig.loginData);
        setInfoResultPayloadHandler(localStorageConfig.infoResultPayload);
        setPlayerStatisticsHandler(localStorageConfig.playerStatistics);
        setPlayerDataHandler(localStorageConfig.playerData);
        setCatalogHandler(localStorageConfig.catalog);
        setIsLogged(true);
    }, [localStorageConfig, setLoginDataHandler, setInfoResultPayloadHandler, setPlayerStatisticsHandler, setPlayerDataHandler, setCatalogHandler]);

    useEffect(() => {
        if (!storageInizialized.current) {
            if (!isLoggedCheck()) {
                setLocalStorageConfig(localStorageData);
            } else {
                setAppData();    // Automatic login if data is in localStorage
            }

            storageInizialized.current = true;
        }

    }, [isLoggedCheck, setAppData, setLocalStorageConfig]);

    
    
    const reAuthenticateSession = (sessionToken: string, entityToken: string) => {
        PlayFab._internalSettings.entityToken = entityToken;
        PlayFab._internalSettings.sessionTicket = sessionToken;

        //TODO- reload control. More than 3, redirect to login
    };

    const createContactEmail = (email: string) => {
        const request: any = {
            EmailAddress: email,
        };

        setIsLoading(true);

        PlayFabClient.AddOrUpdateContactEmail(request, (error, result) => {
            if (error) {
                setError(error);
            } else {
                // Success. No actions
            }
            setIsLoading(false);
        });
    };

    const onNewPlayer = (response: any) => {

        const request: any = {
            FunctionName: 'InitializeNewAccount',
            FunctionParameter: null,
            AuthenticationContext: {
                EntityToken: response.EntityToken.EntityToken,
            },
        };

        setIsLoading(true);

        PlayFabCloudScript.ExecuteFunction(request, (error, result) => {
            if (error) {
                setError(error);
            } else {
                // console.log('onNewPlayer-result', result);
            }
            setIsLoading(false);
        });
    };

    const checkCorrectPlayFabConfig = (): boolean => {
        let informedSettings = false;
        if (PlayFab.settings.titleId && PlayFab.settings.titleId.length > 0  && PlayFab.settings.developerSecretKey && PlayFab.settings.developerSecretKey.length > 0) {
            informedSettings = true;
        }
        return informedSettings;
    };

    const createAccount = (requestConfig: registerUserRequest, applyData: any) => {
        const email: string = requestConfig.Email;
        const request: registerPlayFabUserRequest = {
            DisplayName: requestConfig.DisplayName,
            Email: requestConfig.Email,
            Password: requestConfig.Password,
            Username: requestConfig.Username,
            RequireBothUsernameAndEmail: true,
            TitleId: process.env.REACT_APP_PLAYFAB_TITLE_ID!,
        };

        if (checkCorrectPlayFabConfig()) {
            setIsLoading(true);

            PlayFabClient.RegisterPlayFabUser(request, (error, result) => {
                if (error) {
                    setError(error);
                } else {
                    onNewPlayer(result.data);
                    createContactEmail(email);
                    applyData(result);
                }
                setIsLoading(false);
            });
        } else {
            console.error('Error in Playfab config');
        }
    };

    const isClientLoggedIn = () => {
        return PlayFabClient.IsClientLoggedIn();
    };

    const getCatalogWeb = (playFabId: string) => {
        return new Promise((resolve, reject) => {
            const urlData: string = GET_PLAYFAB_CATALOG_URL;
            const keyMap = {
                Leader: 'Hero',
                Wonder: 'Troop',
            };

            sendForm({
                    url: urlData,
                    method: 'POST',
                    body: playFabId,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                },
                (response: any) => {
                    const catalog = JSON.parse(response.message);
                    const updatedCatalog = Object.fromEntries(
                        Object.entries(catalog).map(([key, value]) => [keyMap[key] || key, value])
                    ); 
                    resolve(updatedCatalog);
                }
            );
        });
    };

    const formatPlayerStatistics = (data: any) => {
        const userProfileData = JSON.parse(data.UserData.Profile.Value);
        const statisticsData = { ...data.PlayerStatistics };
        const accountInfoData = { ...data.AccountInfo };
        const currencyData = { ...data.UserVirtualCurrency };
        const levelsList = JSON.parse(data.TitleData.LevelExp);
        const experienceValue = getPlayerValue(statisticsData, 'ExpPJ');
        const rankValue = getPlayerValue(statisticsData, 'EloRank');
        const userFrameId = userProfileData.idFrame; 
        const userAvatarId = userProfileData.idAvatar; 

        const playerStatistics = {
            playerName: accountInfoData.TitleInfo.DisplayName,
            currency: {
                keys: currencyData.CK,
                gems: currencyData.GM,
                coins: currencyData.SL,
            },
            experience: rankValue,
            level: findPlayerLevel(experienceValue, levelsList),
            userFrameUri: userFrameId,
            userAvatarUri: userAvatarId,
        };

        return playerStatistics;
    };

    const formatPlayerData = (userInventory: any, catalog: any ) => {
        const playerData = getPlayerData(userInventory);
        const playerDataWithCatalog = getPlayerDataWithCatalog(playerData, catalog, csvDescriptionsData);
        return playerDataWithCatalog;
    };

    const fetchAndFormatCatalog = async (userInventory: any, playFabId: string ) => {
        setIsLoading(true);

        try {
            const response = await getCatalogWeb(playFabId);
            return formatPlayerData(userInventory, response);
 
        } catch (error) {
            console.error('Error fetching catalogWeb', error);
            setError(`Error fetching catalogWeb: ${error}`);
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    const login = (requestConfig: loginWithEmailAddressRequest, applyData: any) => {

        const request: loginWithEmailAddressRequest = {
            Email: requestConfig.Email,
            Password: requestConfig.Password,
            InfoRequestParameters: {
                GetCharacterInventories: true,
                GetCharacterList: true,
                GetPlayerProfile: true,
                GetPlayerStatistics: true,
                GetTitleData: true,
                GetUserAccountInfo: true,
                GetUserData: true,
                GetUserInventory: true,
                GetUserReadOnlyData: true,
                GetUserVirtualCurrency: true,
            }
        };

        if (!checkCorrectPlayFabConfig()) {
            console.error('Error in Playfab config');
            return false;
        }

        if (!isLoggedCheck()) {
            setIsLoading(true);

            PlayFabClient.LoginWithEmailAddress(request, async (error, result) => {
                if (error) {
                    setError(error);
                    setIsLoading(false);
                } else {
                    const response = result.data;
                    const playFabId = response.PlayFabId;
                    const { InfoResultPayload, ...rest } = response;
                    let playerStatistics = formatPlayerStatistics(InfoResultPayload);

                    setInfoResultPayloadHandler(InfoResultPayload);

                    setLocalStorageConfig((prevState: localStorageDataType) => ({
                        ...prevState,
                        loginData: rest,
                        infoResultPayload: InfoResultPayload,
                        playerStatistics: playerStatistics,
                        token: response.EntityToken?.EntityToken,
                        expirationTime: response.EntityToken?.TokenExpiration
                    }));

                    setLocalStorageSettings((prevState: localStorageSettingsType) => ({
                        ...prevState,
                        language: localStorageSettings.language,
                    }));

                    setError(false);
                    setIsLoading(false);

                    const playerData = await fetchAndFormatCatalog(InfoResultPayload?.UserInventory, playFabId!);

                    if (playerData) {
                        setPlayerDataHandler(playerData);
                        setCatalogHandler(response);

                        playerStatistics.userFrameUri = playerData.find((item: any) => item.CatalogVersion === 'Frame' && item.ItemId === playerStatistics.userFrameUri).ImageUri || '';
                        playerStatistics.userAvatarUri = playerData.find((item: any) => item.CatalogVersion === 'Avatar' && item.ItemId === playerStatistics.userAvatarUri).ImageUri || '';
                        setPlayerStatisticsHandler(playerStatistics);

                        setLocalStorageConfig((prevState: localStorageDataType) => ({
                            ...prevState,
                            playerData: playerData,
                            catalog: response,
                        }));

                        setIsLogged(true);
                        setLoginDataHandler(rest);
                        setTimeout(() => applyData(result), 2000);
                    }
                }
            });

        } else {
            setAppData();
            applyData(true);
        }
    };

    const recoverPassword = (data: any, applyData: any) => {
        const urlData: string = RECOVER_PASSWORD_URL;
        setIsLoading(true);
        sendForm( {
                url: urlData,
                method: 'POST',
                body: data,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            },
            (response: any) => {
                setIsLoading(false);
                applyData(response);
            }
        );
    };

    // Change password
    const changePassword = (data: any, applyData: any) => {
        const urlData: string = CHANGE_PASSWORD_URL;
        setIsLoading(true);

        sendForm( {
                url: urlData,
                method: 'POST',
                body: data,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            },
            (response: any) => {
                setIsLoading(false);
                applyData(response);
            }
        );
    };

    useEffect(() => {
        if (httpError) {
            console.error('ERROR:', httpError);
            setError(`HTTP error: ${httpError}`);
            setIsLoading(false);
        }
 
     }, [httpError]);

    return {
        isLoading: isLoading,
        error: error,
        isLogged: isLogged,
        createAccount,
        login,
        recoverPassword,
        changePassword,
        isClientLoggedIn,
        reAuthenticateSession
    };
};

export default usePlayFab;
